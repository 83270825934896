<template>
  <app-modal title="Create a new organization" :width="800" @submit="onSubmit">
    <div class="grid gap-6">
      <fieldset>
        <legend class="mb-2 text-base">Organization</legend>

        <div class="grid grid-cols-[1fr_110px] gap-4">
          <div>
            <app-form-input-text
              v-model="form.name"
              data-cy-name
              label="Name"
              required
            />
            <app-form-input-text
              v-model="form.slug"
              data-cy-slug
              label="Slug"
              pattern="^[a-z0-9]+(-[a-z0-9]+)*$"
              required
            />
          </div>

          <app-image-upload-widget
            v-model="form.logo_url"
            class="aspect-square"
            data-cy-avatar
            preset="avatars"
            required
          />
        </div>
      </fieldset>

      <fieldset>
        <legend class="mb-2 text-base">Preferences</legend>

        <organization-preferences-language-select
          v-model="form.preferences.language"
        />
      </fieldset>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { slugify } from "~/shared/url/url.utils";
import type { OrganizationCreationRequest } from "~~/model/organization.model";

const form = reactive<OrganizationCreationRequest>({
  name: "",
  slug: "",
  logo_url: "",
  preferences: {
    language: "fr",
  },
});

const { close } = useModal("organizationCreationModal");

const onSubmit = () => close({ payload: form, confirmed: true });

watchEffect(() => {
  form.slug = slugify(form.name);
});
</script>
